import { useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

const RedirectContainer = ({ goToPath }) => {
  useEffect(() => {
    navigate(goToPath)
  }, [])
  return null
}

RedirectContainer.propTypes = {
  goToPath: PropTypes.string.isRequired,
}

export default RedirectContainer
